.header {
  height: 8em;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
}
.logo__img {
  width:  3rem;
}
.logo__container {
  display: flex;
}
h3 {
  padding-top: .5rem;
}
.light .title__intro {
  font-weight:  500;
  color:  #444;
}
.dark .title__intro {
  color: var(--clr-fg-alt);
}
.dark .icon {
  fill: var(--clr-fg-alt);
}
@media (max-width: 600px) {
  .header {
    height: 6em;
  }
  .logo__container h3 {
    display: none;
  }
}